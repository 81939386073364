import React from 'react';
import { Trans } from 'gatsby-plugin-react-i18next';
import Infinity from '../../assets/svg/infinity.svg';
import Anonymous from '../../assets/svg/anonymous.svg';
import Platforms from '../../assets/svg/platforms.svg';
import Ok from '../../assets/svg/ok.svg';
import Options from '../../assets/svg/options.svg';
import ReadMessage from '../../assets/svg/read_message.svg';
import ListItem from './ListItem';
import * as styles from './PossibilityList.module.scss';

const ICON_SIZE = 40;
const POSSIBILITIES = [
  {
    icon: <Infinity height={ICON_SIZE} width={ICON_SIZE} />,
    title: <Trans>No restrictions</Trans>,
    text: (
      <Trans>
        The number of profiles running simultaneously is limited only by the resources of your
        machine.
      </Trans>
    ),
  },
  {
    icon: <Options height={ICON_SIZE} width={ICON_SIZE} />,
    title: <Trans>No setup</Trans>,
    text: <Trans>Create a quick profile in one click and start working immediately.</Trans>,
  },
  {
    icon: <Anonymous height={ICON_SIZE} width={ICON_SIZE} />,
    title: <Trans>Anonymity</Trans>,
    text: (
      <Trans>
        All fingerprintable properties are controlled deep inside browser code. No site can track
        your real device.
      </Trans>
    ),
  },
  {
    icon: <Platforms height={ICON_SIZE} width={ICON_SIZE} />,
    title: (
      <Trans i18nKey="Windows and MacOS (M series/Intel)">
        Windows, MacOS (M series/Intel) and Linux<sup>Beta</sup>
      </Trans>
    ),
    text: (
      <Trans i18nKey="Octo Browser natively runs on Windows, Intel and Apple Silicon Macs.">
        Octo Browser runs on Windows, Intel/Apple Silicon MacOS and Linux<sup>Beta</sup>
      </Trans>
    ),
  },
  {
    icon: <Ok height={ICON_SIZE} width={ICON_SIZE} />,
    title: <Trans>Pass fingerprinting tests</Trans>,
    text: (
      <Trans>
        Octo Browser successfuly passes such popular checkers as pixelscan, BrowserLeaks, Whoer and
        creepjs.
      </Trans>
    ),
  },
  {
    icon: <ReadMessage height={ICON_SIZE} width={ICON_SIZE} />,
    title: <Trans>Technical support</Trans>,
    text: (
      <Trans>
        We provide quick and professional support, including remote sessions via TeamViewer or
        similar software.
      </Trans>
    ),
  },
];
const PossibilityList = (): React.ReactElement => (
  <div className={styles.wrapper}>
    <div className={styles.innerWrapper}>
      {POSSIBILITIES.map((possibility, idx) => (
        <ListItem
          icon={possibility.icon}
          key={idx}
          text={possibility.text}
          title={possibility.title}
        />
      ))}
    </div>
  </div>
);

export default PossibilityList;
