import React from 'react';
import * as styles from './ListItem.module.scss';
import reviewImgDefaultSrc from '../../assets/images/reviews/default.png';
import reviewImgDefaultSrc2x from '../../assets/images/reviews/default_2x.png';
import ImageContainer from '../ImageContainer/ImageContainer';

const AVATAR_SIZE = '34px';

type ListItemProps = {
  name: string | React.ReactNode;
  text: string | React.ReactNode;
  isUser?: boolean;
  logo?: React.ReactNode;
};

const ListItem = ({
  logo,
  name,
  text,
  isUser,
}: ListItemProps): React.ReactElement => {
  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        <div className={styles.name}>
          {isUser && (
            <div className={styles.avatar}>
              <ImageContainer
                src={reviewImgDefaultSrc}
                srcSet={{ _2x: reviewImgDefaultSrc2x }}
                width={AVATAR_SIZE}
                height={AVATAR_SIZE}
              />
            </div>
          )}
          {name}
        </div>
        {logo && <div>{logo}</div>}
      </header>
      <div className={styles.text}>{text}</div>
    </div>
  );
};

export default ListItem;
