// extracted by mini-css-extract-plugin
export var antBtnLg = "GalleryItem-module--ant-btn-lg--ce0P5";
export var antBtnPrimary = "GalleryItem-module--ant-btn-primary--LEq0x";
export var antFormItem = "GalleryItem-module--ant-form-item--g+W-N";
export var antFormItemLabel = "GalleryItem-module--ant-form-item-label--tUrDS";
export var antInput = "GalleryItem-module--ant-input--+p6P6";
export var antMessageNotice = "GalleryItem-module--ant-message-notice--XdsS-";
export var antMessageNoticeContent = "GalleryItem-module--ant-message-notice-content--d-wLr";
export var button = "GalleryItem-module--button--e3+6g";
export var counter = "GalleryItem-module--counter--fhzuT";
export var errorMessage = "GalleryItem-module--errorMessage--yCdZV";
export var image = "GalleryItem-module--image--ilJtj";
export var info = "GalleryItem-module--info--ujs+6";
export var text = "GalleryItem-module--text--oHSJY";
export var title = "GalleryItem-module--title--X7QE4";
export var wrapper = "GalleryItem-module--wrapper--i93Wh";