import React from 'react';
import { Trans } from 'gatsby-plugin-react-i18next';
import { PRICING_ROUTE } from '../../const/routes.const';
import { ButtonColor } from '../../const/button.conts';
import { LanguageMap } from '../../const/language.const';
import mainNYBlockImgSrc from '../../assets/images/octo_main_ny_block.png';
import mainNYBlockImgSrc2x from '../../assets/images/octo_main_ny_block_2x.png';
import mainNYBlockImgEnSrc from '../../assets/images/octo_main_ny_block_en.png';
import mainNYBlockImgSrcEn2x from '../../assets/images/octo_main_ny_block_en_2x.png';
import mainNYBlockImgPtSrc from '../../assets/images/octo_main_ny_block_pt.png';
import mainNYBlockImgSrcPt2x from '../../assets/images/octo_main_ny_block_pt_2x.png';
import ImageContainer from '../ImageContainer/ImageContainer';
import Button from '../Button/Button';
import useImagesByLocale from '../../hooks/useImagesByLocale.hook';
import * as styles from './MainNYBlock.module.scss';

const MainNYBlock = (): React.ReactElement => {
  const localeImages = useImagesByLocale([
    {
      image: <ImageContainer src={mainNYBlockImgSrc} srcSet={{ _2x: mainNYBlockImgSrc2x }} />,
      locales: {
        include: [LanguageMap.ru.key],
      },
    },
    {
      image: <ImageContainer src={mainNYBlockImgEnSrc} srcSet={{ _2x: mainNYBlockImgSrcEn2x }} />,
      locales: {
        include: [LanguageMap.en.key],
      },
    },
    {
      image: <ImageContainer src={mainNYBlockImgPtSrc} srcSet={{ _2x: mainNYBlockImgSrcPt2x }} />,
      locales: {
        include: [LanguageMap.pt.key],
      },
    },
  ]);

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>
        <Trans>Antidetect browser for multiaccounting</Trans>
      </h1>
      <div className={`${styles.mobileTitle} ${styles.title}`}>
        <Trans>Antidetect browser for multi-accounting</Trans>
      </div>
      <div className={styles.subTitle}>
        <Trans>
          New Year Sale! Buy or renew an Octo Browser subscription for 3, 6, or 12 months to get a massive discount!
        </Trans>
      </div>
      <Button
        color={ButtonColor.white}
        label={<Trans>Get up to -40% discount</Trans>}
        link={PRICING_ROUTE}
      />
      {localeImages.map((image, idx) => (
        <div className={styles.img} key={idx}>
          {image.image}
        </div>
      ))}
    </div>
  );
};

export default MainNYBlock;
