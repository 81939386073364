import React from 'react';
import classNames from 'classnames';
import { Link, Trans } from 'gatsby-plugin-react-i18next';
import * as styles from './GalleryItem.module.scss';

type GalleryItemProps = {
  image: React.ReactNode;
  currentSlide: number;
  total: number;
  title: string | React.ReactNode;
  text: string | React.ReactNode;
  link: string;
  opacity?: boolean;
};

const GalleryItem = ({
  image,
  currentSlide,
  total,
  title,
  text,
  link,
  opacity,
}: GalleryItemProps): React.ReactElement => (
  <div className={classNames(styles.wrapper, opacity && styles.opacity)}>
    <div className={styles.image}>{image}</div>

    <div className={styles.info}>
      <div className={styles.counter}>
        {currentSlide} / {total}
      </div>
      <div className={styles.title}>{title}</div>
      <div className={styles.text}>{text}</div>
      <Link className={styles.button} to={link}>
        <Trans>More</Trans> →
      </Link>
    </div>
  </div>
);

export default GalleryItem;
