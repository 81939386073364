import React from 'react';
import { useSwiper } from 'swiper/react';
import GalleryControl from '../GalleryControl/GalleryControl';
import { GalleryControlType } from '../../types/gallery.types';
import * as styles from './ReviewsListControls.module.scss';

const ReviewsListControls = (): React.ReactElement => {
  const swiper = useSwiper();

  return (
    <>
      <div className={`${styles.control} ${styles.controlPrev}`}>
        <GalleryControl
          type={GalleryControlType.prev}
          onClick={() => swiper.slidePrev()}
          reverseColor
        />
      </div>
      <div className={`${styles.control} ${styles.controlNext}`}>
        <GalleryControl
          type={GalleryControlType.next}
          onClick={() => swiper.slideNext()}
          reverseColor
        />
      </div>
    </>
  );
};

export default ReviewsListControls;
