// extracted by mini-css-extract-plugin
export var antBtnLg = "MainNYBlock-module--ant-btn-lg--Hzs9D";
export var antBtnPrimary = "MainNYBlock-module--ant-btn-primary--dq+M7";
export var antFormItem = "MainNYBlock-module--ant-form-item--+pP-m";
export var antFormItemLabel = "MainNYBlock-module--ant-form-item-label--ougJh";
export var antInput = "MainNYBlock-module--ant-input--4epJ5";
export var antMessageNotice = "MainNYBlock-module--ant-message-notice--LMF9U";
export var antMessageNoticeContent = "MainNYBlock-module--ant-message-notice-content--F2yvo";
export var errorMessage = "MainNYBlock-module--errorMessage--rqVJe";
export var img = "MainNYBlock-module--img--R08rE";
export var mobileTitle = "MainNYBlock-module--mobileTitle--k7d7E";
export var subTitle = "MainNYBlock-module--subTitle--0rKhx";
export var title = "MainNYBlock-module--title--kP7Ee";
export var wrapper = "MainNYBlock-module--wrapper--yjgkf";