// extracted by mini-css-extract-plugin
export var antBtnLg = "ReviewsListControls-module--ant-btn-lg--grDJ5";
export var antBtnPrimary = "ReviewsListControls-module--ant-btn-primary--IWJ+-";
export var antFormItem = "ReviewsListControls-module--ant-form-item--Kykc9";
export var antFormItemLabel = "ReviewsListControls-module--ant-form-item-label--j7GrX";
export var antInput = "ReviewsListControls-module--ant-input--pX6TE";
export var antMessageNotice = "ReviewsListControls-module--ant-message-notice--EPWBt";
export var antMessageNoticeContent = "ReviewsListControls-module--ant-message-notice-content--4Pcmh";
export var control = "ReviewsListControls-module--control--Ic9ri";
export var controlNext = "ReviewsListControls-module--controlNext--6+ftz";
export var controlPrev = "ReviewsListControls-module--controlPrev--uIiaK";
export var errorMessage = "ReviewsListControls-module--errorMessage--U6In3";