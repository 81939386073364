import React from 'react';
import { Link, Trans } from 'gatsby-plugin-react-i18next';
import Faq from '../Faq/Faq';
import { FaqType } from '../../types/faq.types';
import { PRICING_ROUTE } from '../../const/routes.const';

const FAQS: Array<FaqType> = [
  {
    question: <Trans>Is Octo Browser suitable for Facebook or Google?</Trans>,
    answer: (
      <Trans>Yes. Octo Browser is suitable for multi-accounting on any website or service.</Trans>
    ),
  },
  {
    question: <Trans>What payment methods do you accept?</Trans>,
    answer: (
      <Trans i18nKey="faq.main.accept_payment_methods">
        <span>We accept credit cards, crypto, etc. – see the full list on the </span>
        <Link to={PRICING_ROUTE}>pricing page</Link>
        <span>.</span>
      </Trans>
    ),
  },
  {
    question: <Trans>If I can't pay on time, will I lose all my data?</Trans>,
    answer: (
      <Trans>
        Profiles are kept in your account for 6 months. If you do not purchase a subscription within 6 months, all profiles in your account are deleted.
      </Trans>
    ),
  },
  {
    question: <Trans>Can I use the same account on two or more devices?</Trans>,
    answer: (
      <Trans>
        Yes. There are no restrictions on the number of devices. You also can use different profiles
        simultaneously on multiple devices.
      </Trans>
    ),
  },
  {
    question: <Trans>Do you have a mobile version of Octo Browser?</Trans>,
    answer: (
      <Trans i18nKey="No. For now, Octo Browser is available only on desktop (Windows and MacOS).">
        No. For now, Octo Browser is available only on desktop (Windows, MacOS and Linux
        <sup>Beta</sup>).
      </Trans>
    ),
  },
  {
    question: <Trans>What are your competitive advantages?</Trans>,
    answer: <Trans>Top-notch spoofing, usability and unprecedented performance.</Trans>,
  },
];

const FaqMain = (): React.ReactElement => <Faq faqs={FAQS} />;

export default FaqMain;
