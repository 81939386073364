// extracted by mini-css-extract-plugin
export var antBtnLg = "ReviewsList-module--ant-btn-lg--uy-V1";
export var antBtnPrimary = "ReviewsList-module--ant-btn-primary--iX+nz";
export var antFormItem = "ReviewsList-module--ant-form-item--+7Iln";
export var antFormItemLabel = "ReviewsList-module--ant-form-item-label--N+a4j";
export var antInput = "ReviewsList-module--ant-input--j8Rzg";
export var antMessageNotice = "ReviewsList-module--ant-message-notice--D8as2";
export var antMessageNoticeContent = "ReviewsList-module--ant-message-notice-content--+MXq1";
export var errorMessage = "ReviewsList-module--errorMessage--jeOV1";
export var itemWrapper = "ReviewsList-module--itemWrapper--7FMWJ";
export var wrapper = "ReviewsList-module--wrapper--Tq4oq";