import { GALLERY_OPACITY_CLASS_NAME } from '../const/gallery.const';

export const fadeSlide = (
  swipeWrap: HTMLDivElement,
  slideSelector: string,
  activeIndex: number
): void => {
  const slides = swipeWrap.querySelectorAll(`div[data-swiper-slide-index='${activeIndex}']`);

  slides.forEach((slide) => {
    const innerSlide = slide.querySelector(`div[class*="${slideSelector}"]`);

    innerSlide?.classList.add(GALLERY_OPACITY_CLASS_NAME);
  });
};

export const clearFadeSlide = (
  swipeWrap: HTMLDivElement,
): void => {
  const slides = swipeWrap.querySelectorAll(`.${GALLERY_OPACITY_CLASS_NAME}`);

  slides.forEach((slide) => {
    slide?.classList.remove(GALLERY_OPACITY_CLASS_NAME);
  });
};
