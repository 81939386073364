// extracted by mini-css-extract-plugin
export var antBtnLg = "MainBlock-module--ant-btn-lg--bqP6x";
export var antBtnPrimary = "MainBlock-module--ant-btn-primary--018m6";
export var antFormItem = "MainBlock-module--ant-form-item--UBmbs";
export var antFormItemLabel = "MainBlock-module--ant-form-item-label--7dyYy";
export var antInput = "MainBlock-module--ant-input--cHzvV";
export var antMessageNotice = "MainBlock-module--ant-message-notice--IO7S0";
export var antMessageNoticeContent = "MainBlock-module--ant-message-notice-content--pxViA";
export var errorMessage = "MainBlock-module--errorMessage--TNwkY";
export var img = "MainBlock-module--img--qyQ23";
export var mobileTitle = "MainBlock-module--mobileTitle--2Y1yZ";
export var subTitle = "MainBlock-module--subTitle--irKf7";
export var title = "MainBlock-module--title--mHDYl";
export var wrapper = "MainBlock-module--wrapper--Gifsm";