import React from 'react';
import { Trans } from 'gatsby-plugin-react-i18next';
import { SIGN_UP_ROUTE } from '../../const/routes.const';
import mainBlockImgSrc from '../../assets/images/octo_main_block.png';
import mainBlockImgSrc2x from '../../assets/images/octo_main_block_2x.png';
import ImageContainer from '../ImageContainer/ImageContainer';
import Button from '../Button/Button';
import { ButtonColor } from '../../const/button.conts';
import * as styles from './MainBlock.module.scss';

const MainBlock = (): React.ReactElement => (
  <div className={styles.wrapper}>
    <h1 className={styles.title}>
      <Trans>Antidetect browser for multiaccounting</Trans>
    </h1>
    <div className={`${styles.mobileTitle} ${styles.title}`}>
      <Trans>Antidetect browser for multi-accounting</Trans>
    </div>
    <div className={styles.subTitle}>
      <Trans>
        Create and manage any number of accounts without hussle, IP bans and extra expenses.
      </Trans>
    </div>
    <Button
      color={ButtonColor.white}
      label={<Trans>Create Octo Browser account</Trans>}
      link={SIGN_UP_ROUTE}
    />
    <div className={styles.img}>
      <ImageContainer src={mainBlockImgSrc} srcSet={{ _2x: mainBlockImgSrc2x }} />
    </div>
  </div>
);

export default MainBlock;
