// extracted by mini-css-extract-plugin
export var antBtnLg = "ListItem-module--ant-btn-lg--k3HFY";
export var antBtnPrimary = "ListItem-module--ant-btn-primary--PJ1eK";
export var antFormItem = "ListItem-module--ant-form-item--KgLI-";
export var antFormItemLabel = "ListItem-module--ant-form-item-label--ddH4n";
export var antInput = "ListItem-module--ant-input--6OX35";
export var antMessageNotice = "ListItem-module--ant-message-notice--i7Q6g";
export var antMessageNoticeContent = "ListItem-module--ant-message-notice-content--nPfrV";
export var avatar = "ListItem-module--avatar--T8kuS";
export var errorMessage = "ListItem-module--errorMessage--OIMTd";
export var header = "ListItem-module--header--eI1mF";
export var name = "ListItem-module--name--GNyon";
export var text = "ListItem-module--text--Ymb4U";
export var wrapper = "ListItem-module--wrapper--8s4NT";