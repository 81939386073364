// extracted by mini-css-extract-plugin
export var antBtnLg = "Reviews-module--ant-btn-lg--8y5um";
export var antBtnPrimary = "Reviews-module--ant-btn-primary--164tT";
export var antFormItem = "Reviews-module--ant-form-item--m75TM";
export var antFormItemLabel = "Reviews-module--ant-form-item-label--rTeMJ";
export var antInput = "Reviews-module--ant-input--E66R6";
export var antMessageNotice = "Reviews-module--ant-message-notice--LiUVp";
export var antMessageNoticeContent = "Reviews-module--ant-message-notice-content--Hzlq8";
export var errorMessage = "Reviews-module--errorMessage--JrNoV";
export var wrapper = "Reviews-module--wrapper--H3oj8";
export var wrapperEn = "Reviews-module--wrapper-en--75800";