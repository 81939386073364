import React from 'react';
import classNames from 'classnames';
import Arrow from '../../assets/svg/arrow.svg';
import { GalleryControlType } from '../../types/gallery.types';
import * as styles from './GalleryControl.module.scss';

type GalleryControlProps = {
  type: GalleryControlType;
  onClick: () => void;
  size?: number;
  reverseColor?: boolean;
};

const GalleryControl = ({
  type,
  onClick,
  size = 56,
  reverseColor,
}: GalleryControlProps): React.ReactElement => {
  return (
    <div
      className={classNames(styles.wrapper, styles[type], {
        [styles.reverseColor]: reverseColor,
      })}
      style={{ width: `${size}px`, height: `${size}px` }}
      onClick={onClick}
    >
      <div className={styles.arrow}>
        <Arrow />
        <div className={styles.resetSelect}>_</div>
      </div>
    </div>
  );
};

export default GalleryControl;
