import React from 'react';
import * as styles from './Reviews.module.scss';
import ContentWrap from '../ContentWrap/ContentWrap';
import BlockTitle from '../BlockTitle/BlockTitle';
import ReviewsList from './ReviewsList';
import classNames from 'classnames';

const Reviews = (): React.ReactElement => {
  return (
    <div className={classNames(styles.wrapper)}>
      <ContentWrap>
        <BlockTitle
          title={'Что говорят об'}
          highlightText={'Octo Browser'}
          subTitle={'Отзывы и статьи в медиа о нас'}
          maxWidthTitle={810}
        />
        <ReviewsList />
      </ContentWrap>
    </div>
  );
};

export default Reviews;
