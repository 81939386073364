import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import ListItem from './ListItem';
import * as styles from './ReviewsList.module.scss';
import ReviewsListControls from './ReviewsListControls';
import ImageContainer from '../ImageContainer/ImageContainer';
import reviewImg1Src from '../../assets/images/reviews/1.png';
import reviewImg1Src2x from '../../assets/images/reviews/1_2x.png';
import reviewImg2Src from '../../assets/images/reviews/2.png';
import reviewImg2Src2x from '../../assets/images/reviews/2_2x.png';
import reviewImg3Src from '../../assets/images/reviews/3.png';
import reviewImg3Src2x from '../../assets/images/reviews/3_2x.png';
import reviewImg4Src from '../../assets/images/reviews/4.png';
import reviewImg4Src2x from '../../assets/images/reviews/4_2x.png';
import reviewImg5Src from '../../assets/images/reviews/5.png';
import reviewImg5Src2x from '../../assets/images/reviews/5_2x.png';
import classNames from 'classnames';
import { GALLERY_DEFAULT_SPEED, GALLERY_OPACITY_CLASS_NAME } from '../../const/gallery.const';
import useIsTablet from '../../hooks/useIsTablet';
import useIsMobile from '../../hooks/useIsMobile';
import { clearFadeSlide, fadeSlide } from '../../utils/swiper.utils';

const LIST_ITEM_SELECTOR = 'ListItem-module--wrapper';
const REVIEWS = [
  {
    logo: (
      <ImageContainer
        src={reviewImg1Src}
        srcSet={{ _2x: reviewImg1Src2x }}
        width={'31px'}
        height={'34px'}
      />
    ),
    name: 'partnerkin.com',
    text: `На рынке сегодня достаточно много продуктов, но большинство из них либо
        неудобны в использовании, либо технически слабые и юзер выхватывает баны
        чаще, чем мог бы. OctoBrowser — пример сочетания очень приятного
        и удобного интерфейса, а также, что важнее, силен технически и успешно
        обходит защиту большинства сервисов.`,
  },
  {
    logo: (
      <ImageContainer
        src={reviewImg2Src}
        srcSet={{ _2x: reviewImg2Src2x }}
        width={'61px'}
        height={'34px'}
      />
    ),
    name: 'conversion.im',
    text: `Достаточно много медийных лиц рекомендуют Octo, а в больших командах обычно также используют несколько инструментов сразу - покупается все и используется баером то, что удобно ему лично, либо дает лучшие результаты в работе.`,
  },
  {
    logo: (
      <ImageContainer
        src={reviewImg3Src}
        srcSet={{ _2x: reviewImg3Src2x }}
        width={'34px'}
        height={'34px'}
      />
    ),
    name: 'zorbasmedia.ru',
    text: `Octo Browser — необходимый инструмент для каждого арбитражника. Реальные
        фингерпринты, удобный интерфейс, прохождение проверок Pixelscan без
        ошибок и алертов, возможность работы командой и многое другое — всё это
        делает антидетект-браузер сервисом, который поможет существенно
        увеличить свой заработок и охватить новые ниши.`,
  },
  {
    logo: (
      <ImageContainer
        src={reviewImg4Src}
        srcSet={{ _2x: reviewImg4Src2x }}
        width={'74px'}
        height={'34px'}
      />
    ),
    name: 'protraffic.com',
    text: `В Octo Browser собраны все преимущества, которые есть у других
        антидетект браузеров, а главное — решены все проблемы. Область
        применения ограничена лишь фантазией, благодаря API и headless, который
        не палится, вы можете автоматизировать любые рутинные задачи и быть
        уверены в результате.`,
  },
  {
    logo: (
      <ImageContainer
        src={reviewImg5Src}
        srcSet={{ _2x: reviewImg5Src2x }}
        width={'41px'}
        height={'34px'}
      />
    ),
    name: 'gdetraffic.com',
    text: `Арбитражники стали требовательнее относится к платным сервисам,
        а на рынке с такой конкуренцией, как с антиками, именно бесперебойная
        работа браузера, гарантия анонимности и умение исправить ошибку
        за минуты — ценится выше, чем подарки, розыгрыши или скидосы.`,
  },
  {
    name: 'Клиент',
    text: `Пользуюсь несколько месяцев, в замен небезызвестного антидетекта.
        Радует, что разработчики поддерживают свой браузер, а именно во время
        обновляют. Так же радует адекватная быстрая обратная связь.`,
    isUser: true,
  },
  {
    name: 'Клиент',
    text: `Давно пользуемся Octo Browser, еще со времен бета теста. Все стабильно
        работает, интерфейс дружелюбный, поддержка оперативная. Обращаться
        приходится редко (продукт качественный). Создаваемые профили прогоняем
        через пиксельскан, все профили в 100% случаев с правильными отпечатками.
        Акки живут долго.`,
    isUser: true,
  },
  {
    name: 'Клиент',
    text: `Адекватное отношение, даже к людям, которые не являются клиентами,
        предопределило выбор Octo Browser в качестве первого антидетект решения
        на старте нового ответвления в карьере.`,
    isUser: true,
  },
];

const ReviewsList = (): React.ReactElement => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  const handleNextTransitionStart = (idx: number) => {
    if (sliderRef.current) {
      fadeSlide(sliderRef.current, LIST_ITEM_SELECTOR, activeIndex);
      setActiveIndex(idx);
    }
  };

  const handlePrevTransitionStart = (idx: number) => {
    if (sliderRef.current) {
      const isLast = activeIndex + 1 >= REVIEWS.length;
      let prevActiveIndex = activeIndex;

      if (isTablet && !isMobile) {
        prevActiveIndex = isLast ? 0 : prevActiveIndex + 1;
      }

      if (!isTablet && !isMobile) {
        if (isLast) {
          prevActiveIndex = 1;
        } else if (activeIndex + 2 >= REVIEWS.length) {
          prevActiveIndex = 0;
        } else {
          prevActiveIndex = prevActiveIndex + 2;
        }
      }

      fadeSlide(sliderRef.current, LIST_ITEM_SELECTOR, prevActiveIndex);
      setActiveIndex(idx);
    }
  };

  const handleTransitionEnd = () => {
    if (sliderRef.current) {
      clearFadeSlide(sliderRef.current)
    }
  };

  return (
    <div className={classNames(styles.wrapper)} ref={sliderRef}>
      <Swiper
        loop
        speed={GALLERY_DEFAULT_SPEED}
        onSlideNextTransitionStart={(slider) => {
          handleNextTransitionStart(slider.realIndex);
        }}
        onSlidePrevTransitionStart={(slider) => {
          handlePrevTransitionStart(slider.realIndex);
        }}
        onTransitionEnd={handleTransitionEnd}
        slidesPerView={'auto'}
        className={'swiper-with-horizontal-controls'}
      >
        {REVIEWS.map((review, idx) => (
          <SwiperSlide className={styles.itemWrapper} key={idx}>
            <ListItem
              logo={review.logo}
              name={review.name}
              text={review.text}
              isUser={review.isUser}
            />
          </SwiperSlide>
        ))}
        <ReviewsListControls />
      </Swiper>
    </div>
  );
};

export default ReviewsList;
