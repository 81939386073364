import * as React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { IS_NY_SALE_TIME } from '../const/app.const';
import Layout from '../layouts';
import MainBlock from '../components/MainBlock/MainBlock';
import MainNYBlock from '../components/MainNYBlock/MainNYBlock';
import OctoDirections from '../components/Directions/OctoDirections';
import Benefits from '../components/Benefits/Benefits';
import Possibility from '../components/Possibility/Possibility';
import Reviews from '../components/Reviews/Reviews';
import SliderLogos from '../components/SliderLogos/SliderLogos';
import FaqMain from '../components/FaqMain/FqaMain';
import AppRating from '../components/AppRating';
import DownBlock from '../components/DownBlock/DownBlock';
// import BlockFeed from '../components/BlogFeed/BlogFeed';

const IndexPage = (): React.ReactElement => {
  const { language } = useI18next();

  return (
    <Layout>
      <AppRating />
      {IS_NY_SALE_TIME ? <MainNYBlock /> : <MainBlock />}
      <SliderLogos />
      <OctoDirections />
      <Benefits />
      <Possibility />
      {language === 'ru' && <Reviews />}
      {/*<BlockFeed />*/}
      <FaqMain />
      <DownBlock />
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["mainPage", "translation"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default IndexPage;
