import React, { useRef, useState } from 'react';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import ContentWrap from '../ContentWrap/ContentWrap';
import benefit1Src from '../../assets/images/benefits/1.png';
import benefit1SrcEn from '../../assets/images/benefits/1_en.png';
import benefit1Src2x from '../../assets/images/benefits/1_2x.png';
import benefit1Src2xEn from '../../assets/images/benefits/1_2x_en.png';
import benefit2Src from '../../assets/images/benefits/2.png';
import benefit2Src2x from '../../assets/images/benefits/2_2x.png';
import benefit3Src from '../../assets/images/benefits/3.png';
import benefit3Src2x from '../../assets/images/benefits/3_2x.png';
import benefit4Src from '../../assets/images/benefits/4.png';
import benefit4Src2x from '../../assets/images/benefits/4_2x.png';
import benefit5Src from '../../assets/images/benefits/5.png';
import benefit5Src2x from '../../assets/images/benefits/5_2x.png';
import benefit6Src from '../../assets/images/benefits/6.png';
import benefit6Src2x from '../../assets/images/benefits/6_2x.png';
import benefit7Src from '../../assets/images/benefits/7.png';
import benefit7Src2x from '../../assets/images/benefits/7_2x.png';
import ImageContainer from '../ImageContainer/ImageContainer';
import {
  API_AUTOMATION_ROUTE,
  COOKIES_ROUTE,
  FINGERPRINT_MANAGEMENT_ROUTE,
  MULTIACCOUNTING_ROUTE,
  PROFILES_ROUTE,
  PROXY_ROUTE,
  TEAMWORK_ROUTE,
} from '../../const/routes.const';
import { LanguageMap } from '../../const/language.const';
import GalleryItem from './GalleryItem';
import BenefitsGalleryControls from './BenefitsGalleryControls';
import * as styles from './BenefitsGallery.module.scss';
import classNames from 'classnames';
import { GALLERY_DEFAULT_SPEED, GALLERY_OPACITY_CLASS_NAME } from '../../const/gallery.const';
import { clearFadeSlide, fadeSlide } from '../../utils/swiper.utils';

const IMG_HEIGHT = '320px';
const IMG_WIDTH = '478px';

const BenefitsGallery = (): React.ReactElement => {
  const { language } = useI18next();
  const sliderRef = useRef<HTMLDivElement>(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const benefitSrc1 = language === LanguageMap.ru.key ? benefit1Src : benefit1SrcEn;
  const benefitSrc1x2 = language === LanguageMap.ru.key ? benefit1Src2x : benefit1Src2xEn;
  const SLIDES = [
    {
      image: <ImageContainer src={benefitSrc1} srcSet={{ _2x: benefitSrc1x2 }} />,
      title: <Trans>Multi-accounting</Trans>,
      text: (
        <Trans>
          Use thousands of accounts on a single device. Each profile has a distinct browser
          fingerprint of a real machine.
        </Trans>
      ),
      link: MULTIACCOUNTING_ROUTE,
    },
    {
      image: (
        <ImageContainer
          height={IMG_HEIGHT}
          src={benefit2Src}
          srcSet={{ _2x: benefit2Src2x }}
          width={IMG_WIDTH}
        />
      ),
      title: <Trans>Fingerprint management</Trans>,
      text: (
        <Trans>
          Octo Browser allows you to control all fingerprint parameters. The fingerprint is managed in the Chromium code, which protects your device against additional checks.
        </Trans>
      ),
      link: FINGERPRINT_MANAGEMENT_ROUTE,
    },
    {
      image: (
        <ImageContainer
          height={IMG_HEIGHT}
          src={benefit3Src}
          srcSet={{ _2x: benefit3Src2x }}
          width={IMG_WIDTH}
        />
      ),
      title: <Trans>Teamwork</Trans>,
      text: (
        <Trans>
          Transfer or share browser profiles with team members while keeping fingerprints intact.
          Use the same accounts on different devices with no checkpoints and bans for
          multi-accounting.
        </Trans>
      ),
      link: TEAMWORK_ROUTE,
    },
    {
      image: (
        <ImageContainer
          height={IMG_HEIGHT}
          src={benefit4Src}
          srcSet={{ _2x: benefit4Src2x }}
          width={IMG_WIDTH}
        />
      ),
      title: <Trans>API automation</Trans>,
      text: (
        <Trans>
          Automate all account and profile interactions via an API using Puppeteer, Selenium or any
          other libraries.
        </Trans>
      ),
      link: API_AUTOMATION_ROUTE,
    },
    {
      image: (
        <ImageContainer
          height={IMG_HEIGHT}
          src={benefit5Src}
          srcSet={{ _2x: benefit5Src2x }}
          width={IMG_WIDTH}
        />
      ),
      title: <Trans>Profiles</Trans>,
      text: (
        <Trans>
          Create, clone, export and transfer your profiles with no limits. No configuration required
          — just 1 click to create and run a new profile with a distinct fingerprint.
        </Trans>
      ),
      link: PROFILES_ROUTE,
    },
    {
      image: (
        <ImageContainer
          height={IMG_HEIGHT}
          src={benefit6Src}
          srcSet={{ _2x: benefit6Src2x }}
          width={IMG_WIDTH}
        />
      ),
      title: <Trans>Proxy</Trans>,
      text: (
        <Trans>
          Octo Browser supports all popular proxy types: https, socks5, ssh. It allows to save
          proxies for future use and integrates with third-party solutions for changing IPs.
        </Trans>
      ),
      link: PROXY_ROUTE,
    },
    {
      image: (
        <ImageContainer
          height={IMG_HEIGHT}
          src={benefit7Src}
          srcSet={{ _2x: benefit7Src2x }}
          width={IMG_WIDTH}
        />
      ),
      title: <Trans>Cookies</Trans>,
      text: (
        <Trans>
          Import сookies in json and Netscape formats. Automate cookie collection with the builtin
          cookie robot in parallel headless mode.
        </Trans>
      ),
      link: COOKIES_ROUTE,
    },
  ];

  const handleTransitionStart = (idx: number) => {
    if (sliderRef.current) {
      fadeSlide(sliderRef.current, 'GalleryItem-module--wrapper', activeIndex);
      setActiveIndex(idx);
    }
  };

  const handleTransitionEnd = () => {
    if (sliderRef.current) {
      clearFadeSlide(sliderRef.current);
    }
  };

  return (
    <div className={styles.wrapper}>
      <ContentWrap>
        <div className={styles.innerWrapper} ref={sliderRef}>
          <Swiper
            loop
            speed={GALLERY_DEFAULT_SPEED}
            onTransitionStart={(slider) => handleTransitionStart(slider.realIndex)}
            onTransitionEnd={handleTransitionEnd}
          >
            {SLIDES.map((slide, idx) => (
              <SwiperSlide
                className={classNames(styles.slider)}
                key={slide.link}
              >
                <GalleryItem
                  currentSlide={idx + 1}
                  image={slide.image}
                  link={slide.link}
                  text={slide.text}
                  title={slide.title}
                  total={SLIDES.length}
                />
              </SwiperSlide>
            ))}
            <BenefitsGalleryControls />
          </Swiper>
        </div>
      </ContentWrap>
    </div>
  );
};

export default BenefitsGallery;
