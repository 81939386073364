import React from 'react';
import { Trans } from 'gatsby-plugin-react-i18next';
import * as styles from './Possibility.module.scss';
import BlockTitle from '../BlockTitle/BlockTitle';
import PossibilityList from './PossibilityList';
import ContentWrap from '../ContentWrap/ContentWrap';

const Possibility = (): React.ReactElement => {
  return (
    <div className={styles.wrapper}>
      <ContentWrap>
        <BlockTitle
          title={
            <>
              <Trans>Take</Trans>{' '}
            </>
          }
          highlightText={<Trans>full advantage</Trans>}
          highlightIsBlock={true}
          subTitle={
            <Trans>
              Learn about the benefits that make Octo Browser special.
            </Trans>
          }
        />
      </ContentWrap>
      <PossibilityList />
    </div>
  );
};

export default Possibility;
