import React, { ReactNode } from 'react';
import { Link } from 'gatsby-plugin-react-i18next';
import { ImageComponentType } from '../../types/image.types';
import ArrowMore from '../../assets/svg/arrow_more.svg';
import * as styles from './OctoDirection.module.scss';
import useImagesByLocale from '../../hooks/useImagesByLocale.hook';

type OctoDirectionProps = {
  title: ReactNode;
  text: ReactNode;
  link: string;
  images: ImageComponentType[];
};

const OctoDirection = ({
  title,
  text,
  link,
  images,
}: OctoDirectionProps): React.ReactElement => {
  const localeImages = useImagesByLocale(images);

  return (
    <div className={styles.wrapper}>
      <Link to={link}>
        <div className={styles.wrapperInner}>
          <div>
            <div className={styles.images}>
              {localeImages.map((image, idx) => (
                <div className={styles.image} key={idx}>
                  {image.image}
                </div>
              ))}
            </div>
            <div className={styles.title}>{title}</div>
            <div className={styles.text}>{text}</div>
          </div>
          <div className={styles.link}>
            <ArrowMore />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default OctoDirection;
