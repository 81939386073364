// extracted by mini-css-extract-plugin
export var antBtnLg = "PossibilityList-module--ant-btn-lg--mYT3H";
export var antBtnPrimary = "PossibilityList-module--ant-btn-primary--Q3+Kw";
export var antFormItem = "PossibilityList-module--ant-form-item--RyuSX";
export var antFormItemLabel = "PossibilityList-module--ant-form-item-label--OpZpw";
export var antInput = "PossibilityList-module--ant-input--rLBQi";
export var antMessageNotice = "PossibilityList-module--ant-message-notice--IwuCz";
export var antMessageNoticeContent = "PossibilityList-module--ant-message-notice-content--3ODXs";
export var errorMessage = "PossibilityList-module--errorMessage--1MiSN";
export var innerWrapper = "PossibilityList-module--innerWrapper--dMOKQ";
export var wrapper = "PossibilityList-module--wrapper--Wwm4A";