// extracted by mini-css-extract-plugin
export var antBtnLg = "OctoDirections-module--ant-btn-lg--jWm+O";
export var antBtnPrimary = "OctoDirections-module--ant-btn-primary--C74ek";
export var antFormItem = "OctoDirections-module--ant-form-item--2-Qgo";
export var antFormItemLabel = "OctoDirections-module--ant-form-item-label--EK20-";
export var antInput = "OctoDirections-module--ant-input--zM4L9";
export var antMessageNotice = "OctoDirections-module--ant-message-notice--uOWBJ";
export var antMessageNoticeContent = "OctoDirections-module--ant-message-notice-content--K2Eoa";
export var cards = "OctoDirections-module--cards--SgZR8";
export var cardsAll = "OctoDirections-module--cardsAll--J1-7G";
export var errorMessage = "OctoDirections-module--errorMessage--4SMTk";
export var showMore = "OctoDirections-module--showMore--iXtsZ";
export var wrapper = "OctoDirections-module--wrapper--cX0Ok";