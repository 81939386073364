import React from 'react';
import { Trans } from 'gatsby-plugin-react-i18next';
import ContentWrap from '../ContentWrap/ContentWrap';
import {
  SOLUTIONS_ANONYMITY_ROUTE,
  SOLUTIONS_BOUNTY_ROUTE,
  SOLUTIONS_DIGITAL_AGENCIES_ROUTE,
  SOLUTIONS_ECOMMERCE_ROUTE,
  SOLUTIONS_HUNTING_ROUTE,
  SOLUTIONS_SCRAPPING_ROUTE,
  SOLUTIONS_TRAFFIC_ROUTE,
} from '../../const/routes.const';
import BlockTitle from '../BlockTitle/BlockTitle';
import ImageContainer from '../ImageContainer/ImageContainer';
import directionFbSrc from '../../assets/images/directions/fb.png';
import directionFbSrc2x from '../../assets/images/directions/fb_2x.png';
import directionGaSrc from '../../assets/images/directions/ga.png';
import directionGaSrc2x from '../../assets/images/directions/ga_2x.png';
import directionInstSrc from '../../assets/images/directions/inst.png';
import directionInstSrc2x from '../../assets/images/directions/inst_2x.png';
import directionTikTokSrc from '../../assets/images/directions/tiktok.png';
import directionTikTokSrc2x from '../../assets/images/directions/tiktok_2x.png';
import directionYadSrc from '../../assets/images/directions/yad.png';
import directionYadSrc2x from '../../assets/images/directions/yad_2x.png';
import directionEbSrc from '../../assets/images/directions/eb.png';
import directionEbSrc2x from '../../assets/images/directions/eb_2x.png';
import directionShopySrc from '../../assets/images/directions/shopy.png';
import directionShopySrc2x from '../../assets/images/directions/shopy_2x.png';
import directionAmazSrc from '../../assets/images/directions/amaz.png';
import directionAmazSrc2x from '../../assets/images/directions/amaz_2x.png';
import directionWildSrc from '../../assets/images/directions/wild.png';
import directionWildSrc2x from '../../assets/images/directions/wild_2x.png';
import directionAliSrc from '../../assets/images/directions/ali.png';
import directionAliSrc2x from '../../assets/images/directions/ali_2x.png';
import directionPSrc from '../../assets/images/directions/p.png';
import directionPSrc2x from '../../assets/images/directions/p_2x.png';
import directionCoinSrc from '../../assets/images/directions/coin.png';
import directionCoinSrc2x from '../../assets/images/directions/coin_2x.png';
import directionRabSrc from '../../assets/images/directions/rab.png';
import directionRabSrc2x from '../../assets/images/directions/rab_2x.png';
import directionUnicornSrc from '../../assets/images/directions/unicorn.png';
import directionUnicornSrc2x from '../../assets/images/directions/unicorn_2x.png';
import directionDatasSrc from '../../assets/images/directions/datas.png';
import directionDatasSrc2x from '../../assets/images/directions/datas_2x.png';
import directionFonSrc from '../../assets/images/directions/fon.png';
import directionFonSrc2x from '../../assets/images/directions/fon_2x.png';
import directionWinSrc from '../../assets/images/directions/win.png';
import directionWinSrc2x from '../../assets/images/directions/win_2x.png';
import direction1xStavkaSrc from '../../assets/images/directions/1x_stavka.png';
import direction1xStavkaSrc2x from '../../assets/images/directions/1x_stavka_2x.png';
import directionOlimpSrc from '../../assets/images/directions/olimp.png';
import directionOlimpSrc2x from '../../assets/images/directions/olimp_2x.png';
import directionIncognitoSrc from '../../assets/images/directions/incognito.png';
import directionIncognitoSrc2x from '../../assets/images/directions/incognito_2x.png';
import directionChartSrc from '../../assets/images/directions/chart.png';
import directionChartSrc2x from '../../assets/images/directions/chart_2x.png';
import directionBet365Src from '../../assets/images/directions/bet365.png';
import directionBet365Src2x from '../../assets/images/directions/bet365_2x.png';
import directionBetwaySrc from '../../assets/images/directions/betway.png';
import directionBetwaySrc2x from '../../assets/images/directions/betway_2x.png';
import direction1xSrc from '../../assets/images/directions/1x.png';
import direction1xSrc2x from '../../assets/images/directions/1x_2x.png';
import directionBwinSrc from '../../assets/images/directions/bwin.png';
import directionBwinSrc2x from '../../assets/images/directions/bwin_2x.png';
import * as styles from './OctoDirections.module.scss';
import OctoDirection from './OctoDirection';
import { LanguageMap } from '../../const/language.const';

const DIRECTIONS = [
  {
    title: <Trans>Affiliate Marketing</Trans>,
    text: (
      <Trans>
        Create and manage an unlimited amount of accounts on Facebook, Google
        Ads, TikTok.
      </Trans>
    ),
    link: SOLUTIONS_TRAFFIC_ROUTE,
    images: [
      {
        image: (
          <ImageContainer
            height="26px"
            src={directionInstSrc}
            srcSet={{ _2x: directionInstSrc2x }}
            width="25px"
          />
        ),
      },
      {
        image: (
          <ImageContainer
            height="23px"
            src={directionFbSrc}
            srcSet={{ _2x: directionFbSrc2x }}
            width="22px"
          />
        ),
      },
      {
        image: (
          <ImageContainer
            height="30px"
            src={directionGaSrc}
            srcSet={{ _2x: directionGaSrc2x }}
            width="23px"
          />
        ),
      },
      {
        image: (
          <ImageContainer
            height="21px"
            src={directionYadSrc}
            srcSet={{ _2x: directionYadSrc2x }}
            width="27px"
          />
        ),
        locales: {
          include: [LanguageMap.ru.key],
        },
      },
      {
        image: (
          <ImageContainer
            height="28px"
            src={directionTikTokSrc}
            srcSet={{ _2x: directionTikTokSrc2x }}
            width="28px"
          />
        ),
        locales: {
          exclude: [LanguageMap.ru.key],
        },
      },
    ],
  },
  {
    title: <Trans>E-commerce</Trans>,
    text: (
      <Trans>
        Сreate any number of accounts and bump your income on Amazon, Shopify,
        eBay, AliExpress or Avito.
      </Trans>
    ),
    link: SOLUTIONS_ECOMMERCE_ROUTE,
    images: [
      {
        image: (
          <ImageContainer
            height="14px"
            src={directionEbSrc}
            srcSet={{ _2x: directionEbSrc2x }}
            width="35px"
          />
        ),
      },
      {
        image: (
          <ImageContainer
            height="22px"
            src={directionShopySrc}
            srcSet={{ _2x: directionShopySrc2x }}
            width="25px"
          />
        ),
      },
      {
        image: (
          <ImageContainer
            height="24px"
            src={directionAmazSrc}
            srcSet={{ _2x: directionAmazSrc2x }}
            width="29px"
          />
        ),
      },
      {
        image: (
          <ImageContainer
            height="4px"
            src={directionWildSrc}
            srcSet={{ _2x: directionWildSrc2x }}
            width="37px"
          />
        ),
        locales: {
          include: [LanguageMap.ru.key],
        },
      },
      {
        image: (
          <ImageContainer
            height="5px"
            src={directionAliSrc}
            srcSet={{ _2x: directionAliSrc2x }}
            width="35px"
          />
        ),
        locales: {
          exclude: [LanguageMap.ru.key],
        },
      },
    ],
  },
  {
    title: <Trans>Bounty & Airdrop</Trans>,
    text: (
      <Trans>
        Proven success stories in working with Coinlist and other token sale
        launchpads.
      </Trans>
    ),
    link: SOLUTIONS_BOUNTY_ROUTE,
    images: [
      {
        image: (
          <ImageContainer
            height="28px"
            src={directionPSrc}
            srcSet={{ _2x: directionPSrc2x }}
            width="28px"
          />
        ),
      },
      {
        image: (
          <ImageContainer
            height="33px"
            src={directionCoinSrc}
            srcSet={{ _2x: directionCoinSrc2x }}
            width="33px"
          />
        ),
      },
      {
        image: (
          <ImageContainer
            height="28px"
            src={directionRabSrc}
            srcSet={{ _2x: directionRabSrc2x }}
            width="28px"
          />
        ),
      },
      {
        image: (
          <ImageContainer
            height="30px"
            src={directionUnicornSrc}
            srcSet={{ _2x: directionUnicornSrc2x }}
            width="26px"
          />
        ),
      },
    ],
  },
  {
    title: <Trans>Веб-скрейпинг</Trans>,
    text: (
      <Trans>
        Automate data collection and analysis without fear of being banned.
        Reduce costs of physical and virtual resources.
      </Trans>
    ),
    link: SOLUTIONS_SCRAPPING_ROUTE,
    images: [
      {
        image: (
          <ImageContainer
            height="26px"
            src={directionDatasSrc}
            srcSet={{ _2x: directionDatasSrc2x }}
            width="32px"
          />
        ),
      },
    ],
  },
  {
    title: <Trans>Bonus Hunting</Trans>,
    text: (
      <Trans>
        Use any number of bookmaker or casino accounts on a single device with
        Octo Browser.
      </Trans>
    ),
    link: SOLUTIONS_HUNTING_ROUTE,
    images: [
      {
        image: (
          <ImageContainer
            height="7px"
            src={directionFonSrc}
            srcSet={{ _2x: directionFonSrc2x }}
            width="36px"
          />
        ),
        locales: {
          include: [LanguageMap.ru.key],
        },
      },
      {
        image: (
          <ImageContainer
            height="9px"
            src={directionWinSrc}
            srcSet={{ _2x: directionWinSrc2x }}
            width="38px"
          />
        ),
        locales: {
          include: [LanguageMap.ru.key],
        },
      },
      {
        image: (
          <ImageContainer
            height="6px"
            src={direction1xStavkaSrc}
            srcSet={{ _2x: direction1xStavkaSrc2x }}
            width="39px"
          />
        ),
        locales: {
          include: [LanguageMap.ru.key],
        },
      },
      {
        image: (
          <ImageContainer
            height="18px"
            src={directionOlimpSrc}
            srcSet={{ _2x: directionOlimpSrc2x }}
            width="30px"
          />
        ),
        locales: {
          include: [LanguageMap.ru.key],
        },
      },
      {
        image: (
          <ImageContainer
            height="21px"
            src={directionBet365Src}
            srcSet={{ _2x: directionBet365Src2x }}
            width="34px"
          />
        ),
        locales: {
          exclude: [LanguageMap.ru.key],
        },
      },
      {
        image: (
          <ImageContainer
            height="10px"
            src={directionBetwaySrc}
            srcSet={{ _2x: directionBetwaySrc2x }}
            width="35px"
          />
        ),
        locales: {
          exclude: [LanguageMap.ru.key],
        },
      },
      {
        image: (
          <ImageContainer
            height="20px"
            src={direction1xSrc}
            srcSet={{ _2x: direction1xSrc2x }}
            width="34px"
          />
        ),
        locales: {
          exclude: [LanguageMap.ru.key],
        },
      },
      {
        image: (
          <ImageContainer
            height="17px"
            src={directionBwinSrc}
            srcSet={{ _2x: directionBwinSrc2x }}
            width="36px"
          />
        ),
        locales: {
          exclude: [LanguageMap.ru.key],
        },
      },
    ],
  },
  {
    title: <Trans>Анонимность</Trans>,
    text: (
      <Trans>
        Make a new digital identity and disappear into the crowd. Sites are
        unable to fingerprint your real device parameters.
      </Trans>
    ),
    link: SOLUTIONS_ANONYMITY_ROUTE,
    images: [
      {
        image: (
          <ImageContainer
            height="25px"
            src={directionIncognitoSrc}
            srcSet={{ _2x: directionIncognitoSrc2x }}
            width="31px"
          />
        ),
      },
    ],
  },
  {
    title: <Trans>Digital-агентства</Trans>,
    text: (
      <Trans>
        Масштабируйте рекламные кампании в интернете, защищаясь от рисков
        блокировки аккаунтов.
      </Trans>
    ),
    link: SOLUTIONS_DIGITAL_AGENCIES_ROUTE,
    images: [
      {
        image: (
          <ImageContainer
            height="24px"
            src={directionChartSrc}
            srcSet={{ _2x: directionChartSrc2x }}
            width="24px"
          />
        ),
      },
    ],
  },
];

const OctoDirections = (): React.ReactElement => (
  <div className={styles.wrapper}>
    <ContentWrap>
      <BlockTitle
        highlightIsBlock
        highlightText={<Trans>to rule them all</Trans>}
        maxWidthSubtitle={840}
        maxWidthTitle={960}
        subTitle={
          <Trans>
            Octo Browser easily handles multi-accounting tasks for any service —
            Amazon, CoinList, Facebook, TikTok, Google, Winline.
          </Trans>
        }
        title={<Trans>One antidetect</Trans>}
      />
      <div className={styles.cards}>
        {DIRECTIONS.map((direction, idx) => (
          <OctoDirection
            images={direction.images}
            key={idx}
            link={direction.link}
            text={direction.text}
            title={direction.title}
          />
        ))}
      </div>
    </ContentWrap>
  </div>
);

export default OctoDirections;
