import React from 'react';
import * as styles from './ListItem.module.scss';

type ListItemProps = {
  icon: React.ReactNode;
  title: string | React.ReactNode;
  text: string | React.ReactNode;
};

const ListItem = ({ icon, title, text }: ListItemProps): React.ReactElement => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperInner}>
        <div className={styles.icon}>{icon}</div>
        <div className={styles.title}>{title}</div>
        <div className={styles.text}>{text}</div>
      </div>
    </div>
  );
};

export default ListItem;
