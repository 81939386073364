// extracted by mini-css-extract-plugin
export var antBtnLg = "GalleryControl-module--ant-btn-lg--UTAxN";
export var antBtnPrimary = "GalleryControl-module--ant-btn-primary--2wTxO";
export var antFormItem = "GalleryControl-module--ant-form-item--m8SPB";
export var antFormItemLabel = "GalleryControl-module--ant-form-item-label--Fdd+0";
export var antInput = "GalleryControl-module--ant-input--GURVD";
export var antMessageNotice = "GalleryControl-module--ant-message-notice--oxJxX";
export var antMessageNoticeContent = "GalleryControl-module--ant-message-notice-content--bpNej";
export var arrow = "GalleryControl-module--arrow--LCPsf";
export var errorMessage = "GalleryControl-module--errorMessage--HtSrG";
export var next = "GalleryControl-module--next--LoNu3";
export var prev = "GalleryControl-module--prev--tWl4g";
export var resetSelect = "GalleryControl-module--resetSelect--rc+3R";
export var reverseColor = "GalleryControl-module--reverseColor--Is-Kj";
export var wrapper = "GalleryControl-module--wrapper--chKZP";