import React from 'react';
import Ticker from 'react-ticker';
import { useI18next } from 'gatsby-plugin-react-i18next';
import ImageContainer from '../ImageContainer/ImageContainer';
import slide1Src from '../../assets/images/sliderLogos/1.png';
import slide1Src2x from '../../assets/images/sliderLogos/1_2x.png';
import slide2Src from '../../assets/images/sliderLogos/2.png';
import slide2Src2x from '../../assets/images/sliderLogos/2_2x.png';
import slide3Src from '../../assets/images/sliderLogos/3.png';
import slide3Src2x from '../../assets/images/sliderLogos/3_2x.png';
import slide4Src from '../../assets/images/sliderLogos/4.png';
import slide4Src2x from '../../assets/images/sliderLogos/4_2x.png';
import slide5Src from '../../assets/images/sliderLogos/5.png';
import slide5Src2x from '../../assets/images/sliderLogos/5_2x.png';
import slide6Src from '../../assets/images/sliderLogos/6.png';
import slide6Src2x from '../../assets/images/sliderLogos/6_2x.png';
import slide7Src from '../../assets/images/sliderLogos/7.png';
import slide7Src2x from '../../assets/images/sliderLogos/7_2x.png';
import slide8Src from '../../assets/images/sliderLogos/8.png';
import slide8Src2x from '../../assets/images/sliderLogos/8_2x.png';
import slide9Src from '../../assets/images/sliderLogos/9.png';
import slide9Src2x from '../../assets/images/sliderLogos/9_2x.png';
import slide10Src from '../../assets/images/sliderLogos/10.png';
import slide10Src2x from '../../assets/images/sliderLogos/10_2x.png';
import slide11Src from '../../assets/images/sliderLogos/11.png';
import slide11Src2x from '../../assets/images/sliderLogos/11_2x.png';
import slide12Src from '../../assets/images/sliderLogos/12.png';
import slide12Src2x from '../../assets/images/sliderLogos/12_2x.png';
import slide13Src from '../../assets/images/sliderLogos/13.png';
import slide13Src2x from '../../assets/images/sliderLogos/13_2x.png';
import { LanguageMap } from '../../const/language.const';
import * as styles from './SliderLogos.module.scss';

const IMG_HEIGHT = '120px';

const SliderLogos = (): React.ReactElement => {
  const { language } = useI18next();

  const ruSlides = () => (
    <div className={styles.ticker}>
      <ImageContainer
        height={IMG_HEIGHT}
        src={slide1Src}
        srcSet={{ _2x: slide1Src2x }}
        width="133px"
      />
      <ImageContainer
        height={IMG_HEIGHT}
        src={slide2Src}
        srcSet={{ _2x: slide2Src2x }}
        width="153px"
      />
      <ImageContainer
        height={IMG_HEIGHT}
        src={slide3Src}
        srcSet={{ _2x: slide3Src2x }}
        width="136px"
      />
      <ImageContainer
        height={IMG_HEIGHT}
        src={slide4Src}
        srcSet={{ _2x: slide4Src2x }}
        width="169px"
      />
      <ImageContainer
        height={IMG_HEIGHT}
        src={slide5Src}
        srcSet={{ _2x: slide5Src2x }}
        width="64px"
      />
      <ImageContainer
        height={IMG_HEIGHT}
        src={slide6Src}
        srcSet={{ _2x: slide6Src2x }}
        width="174px"
      />
      <ImageContainer
        height={IMG_HEIGHT}
        src={slide7Src}
        srcSet={{ _2x: slide7Src2x }}
        width="83px"
      />
      <ImageContainer
        height={IMG_HEIGHT}
        src={slide8Src}
        srcSet={{ _2x: slide8Src2x }}
        width="101px"
      />
      <ImageContainer
        height={IMG_HEIGHT}
        src={slide9Src}
        srcSet={{ _2x: slide9Src2x }}
        width="133px"
      />
      <ImageContainer
        height={IMG_HEIGHT}
        src={slide10Src}
        srcSet={{ _2x: slide10Src2x }}
        width="125px"
      />
      <ImageContainer
        height={IMG_HEIGHT}
        src={slide11Src}
        srcSet={{ _2x: slide11Src2x }}
        width="116px"
      />
      <ImageContainer
        height={IMG_HEIGHT}
        src={slide12Src}
        srcSet={{ _2x: slide12Src2x }}
        width="86px"
      />
      <ImageContainer
        height={IMG_HEIGHT}
        src={slide13Src}
        srcSet={{ _2x: slide13Src2x }}
        width="179px"
      />
    </div>
  );

  const enSlides = () => (
    <div className={styles.ticker}>
      <ImageContainer
        height={IMG_HEIGHT}
        src={slide1Src}
        srcSet={{ _2x: slide1Src2x }}
        width="133px"
      />
      <ImageContainer
        height={IMG_HEIGHT}
        src={slide2Src}
        srcSet={{ _2x: slide2Src2x }}
        width="153px"
      />
      <ImageContainer
        height={IMG_HEIGHT}
        src={slide3Src}
        srcSet={{ _2x: slide3Src2x }}
        width="136px"
      />
      <ImageContainer
        height={IMG_HEIGHT}
        src={slide5Src}
        srcSet={{ _2x: slide5Src2x }}
        width="64px"
      />
      <ImageContainer
        height={IMG_HEIGHT}
        src={slide6Src}
        srcSet={{ _2x: slide6Src2x }}
        width="174px"
      />
      <ImageContainer
        height={IMG_HEIGHT}
        src={slide7Src}
        srcSet={{ _2x: slide7Src2x }}
        width="83px"
      />
      <ImageContainer
        height={IMG_HEIGHT}
        src={slide9Src}
        srcSet={{ _2x: slide9Src2x }}
        width="133px"
      />
      <ImageContainer
        height={IMG_HEIGHT}
        src={slide10Src}
        srcSet={{ _2x: slide10Src2x }}
        width="125px"
      />
      <ImageContainer
        height={IMG_HEIGHT}
        src={slide11Src}
        srcSet={{ _2x: slide11Src2x }}
        width="116px"
      />
      <ImageContainer
        height={IMG_HEIGHT}
        src={slide12Src}
        srcSet={{ _2x: slide12Src2x }}
        width="86px"
      />
      <ImageContainer
        height={IMG_HEIGHT}
        src={slide13Src}
        srcSet={{ _2x: slide13Src2x }}
        width="179px"
      />
    </div>
  );

  const slides = language === LanguageMap.ru.key ? ruSlides : enSlides;

  return (
    <div className={styles.wrapper}>
      <Ticker>{slides}</Ticker>
    </div>
  );
};

export default SliderLogos;
