// extracted by mini-css-extract-plugin
export var antBtnLg = "SliderLogos-module--ant-btn-lg--iaWdq";
export var antBtnPrimary = "SliderLogos-module--ant-btn-primary--ZZL6e";
export var antFormItem = "SliderLogos-module--ant-form-item--P39ne";
export var antFormItemLabel = "SliderLogos-module--ant-form-item-label--pl5vr";
export var antInput = "SliderLogos-module--ant-input--itXNv";
export var antMessageNotice = "SliderLogos-module--ant-message-notice--UZNWL";
export var antMessageNoticeContent = "SliderLogos-module--ant-message-notice-content--6u594";
export var errorMessage = "SliderLogos-module--errorMessage--C0KeE";
export var ticker = "SliderLogos-module--ticker--URgLa";
export var wrapper = "SliderLogos-module--wrapper--vF32X";