import React from 'react';
import { useSwiper } from 'swiper/react';
import { GalleryControlType } from '../../types/gallery.types';
import GalleryControl from '../GalleryControl/GalleryControl';
import * as styles from './BenefitsGalleryControls.module.scss';

const BenefitsGalleryControls = (): React.ReactElement => {
  const swiper = useSwiper();

  return (
    <>
      <div className={`${styles.control} ${styles.controlPrev}`}>
        <GalleryControl
          type={GalleryControlType.prev}
          onClick={() => swiper.slidePrev()}
        />
      </div>
      <div className={`${styles.control} ${styles.controlNext}`}>
        <GalleryControl
          type={GalleryControlType.next}
          onClick={() => swiper.slideNext()}
        />
      </div>
    </>
  )
};

export default BenefitsGalleryControls;
