// extracted by mini-css-extract-plugin
export var antBtnLg = "BenefitsGallery-module--ant-btn-lg--4fnX5";
export var antBtnPrimary = "BenefitsGallery-module--ant-btn-primary--Dk-Jy";
export var antFormItem = "BenefitsGallery-module--ant-form-item--am4n8";
export var antFormItemLabel = "BenefitsGallery-module--ant-form-item-label--15lhs";
export var antInput = "BenefitsGallery-module--ant-input--9Wyn1";
export var antMessageNotice = "BenefitsGallery-module--ant-message-notice--p6fB7";
export var antMessageNoticeContent = "BenefitsGallery-module--ant-message-notice-content--QRYcG";
export var errorMessage = "BenefitsGallery-module--errorMessage--0TJ7X";
export var innerWrapper = "BenefitsGallery-module--innerWrapper--QYYFt";
export var wrapper = "BenefitsGallery-module--wrapper--2dSYj";