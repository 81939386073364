// extracted by mini-css-extract-plugin
export var antBtnLg = "OctoDirection-module--ant-btn-lg--+8I6P";
export var antBtnPrimary = "OctoDirection-module--ant-btn-primary--c6vTC";
export var antFormItem = "OctoDirection-module--ant-form-item--+o8wV";
export var antFormItemLabel = "OctoDirection-module--ant-form-item-label--lpXSj";
export var antInput = "OctoDirection-module--ant-input--g0XCt";
export var antMessageNotice = "OctoDirection-module--ant-message-notice--0t7r-";
export var antMessageNoticeContent = "OctoDirection-module--ant-message-notice-content--JLAbr";
export var cards = "OctoDirection-module--cards--WIAt6";
export var errorMessage = "OctoDirection-module--errorMessage--2+qsi";
export var image = "OctoDirection-module--image--PEFsT";
export var images = "OctoDirection-module--images--zll18";
export var link = "OctoDirection-module--link--JNBcq";
export var text = "OctoDirection-module--text--Ft3KK";
export var title = "OctoDirection-module--title--KcCHw";
export var wrapper = "OctoDirection-module--wrapper--W32XI";
export var wrapperInner = "OctoDirection-module--wrapperInner--YHYkC";