import React from 'react';
import { Trans } from 'gatsby-plugin-react-i18next';
import BlockTitle from '../BlockTitle/BlockTitle';
import ContentWrap from '../ContentWrap/ContentWrap';
import * as styles from './Benefits.module.scss';
import BenefitsGallery from './BenefitsGallery';

const Benefits = (): React.ReactElement => (
  <div className={styles.wrapper}>
    <ContentWrap>
      <BlockTitle
        highlightText={<Trans>you need</Trans>}
        maxWidthSubtitle={540}
        subTitle={
          <Trans>
            Check out features that unleash the unlimited potential of Octo
            Browser.
          </Trans>
        }
        title={<Trans>Everything</Trans>}
      />
    </ContentWrap>
    <BenefitsGallery />
  </div>
);

export default Benefits;
